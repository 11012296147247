import { Box, Flex, Text } from '@chakra-ui/react'
import { format } from 'date-fns'

import { FC } from 'react'

interface Props {
	messageData: MessageType
}

export type MessageType = {
	id: string
	author: string
	body: string
	createdAt: string
}

const ChatMessage: FC<Props> = props => {
	const { messageData } = props
	const isMe = messageData.author.includes('Ruhdental')

	return (
		<>
			<Box
				id={messageData.id}
				maxWidth='48%'
				width='auto'
				px={4}
				py={2}
				margin='5px'
				rounded='2xl'
				bg={isMe ? '#cffbcb' : '#fff'}
				borderBottomLeftRadius={isMe ? '2xl' : '0'}
				borderBottomRightRadius={isMe ? '0' : '2xl'}
				alignSelf={isMe ? 'end' : 'start'}
			>
				<Flex gap={1} direction='column' w='full'>
					<Flex gap='0.8em' align='center' fontWeight='bold' justify='space-between'>
						<Text fontSize='0.65em' color='#111b21'>
							{messageData.author}
						</Text>
						<Text fontSize='0.65em' fontWeight='bold' color='#111b21'>
							{format(new Date(messageData.createdAt), 'LLL dd y, HH:mm')}
						</Text>
					</Flex>
					<Text whiteSpace='pre-line' fontSize='0.8em' color='#111b21' textAlign='left'>
						{messageData.body}
					</Text>
				</Flex>
			</Box>
		</>
	)
}

export default ChatMessage
